import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ObjectList from './ObjectList.js';
import GridObjectItem from './gridObjectItem.js';
import ComponentCounter from './ComponentCounter.js';
import Modal from './Modal.js';
import { connect } from 'react-redux';
import { logoutUser } from '../actions/authActions';
import { toggleModal } from '../actions/gridActions';
import html2canvas from 'html2canvas';
import Navbar from './NavBar.js';
import '../styles/App.css';

class GridLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggedItem: [],
      addedComponents: [],
      counter: 0,
      componentX: 0,
      componentY: 0,
      movingComponentId: '',
      gridWidth: 0,
      gridHeight: 0,
      scale: 0,
      activeItem: 0,
      gridSize: '28px 28px',
      counterList: '',
      gridX: '',
      gridY: '',
      imgData: '',
    };
  }

  componentDidMount() {
    // this.setGridSize();
    this.findGridSize();
    this.setSize();
    this.loadGrid();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.grid.width !== this.props.grid.width || prevProps.grid.height !== this.props.grid.height) {
      this.setSize();
      // this.setGridSize();
    }
  }

  findGridSize = () => {
    const screenWidth = this.refs['gridContainer'].offsetWidth;
    const gridWidth = Math.round((screenWidth * 0.8) / 100) * 100;
    const screenHeight = this.refs['gridContainer'].offsetWidth;
    const gridHeight = Math.round((screenHeight * 0.8) / 100) * 100;
    const scale = gridWidth / this.props.grid.width;
    this.setState({
      gridWidth: gridWidth,
      gridHeight: gridHeight,
      scale: scale,
    });
  };

  gridIsSquare = (width, height) => {
    let sideLength;
    let scale;
    if (width > height) {
      sideLength = Math.round((height * 0.8) / 100) * 100;
    } else {
      sideLength = Math.round((width * 0.8) / 100) * 100;
    }
    scale = sideLength / this.props.grid.width;
    this.setState(
      {
        gridWidth: sideLength,
        gridHeight: sideLength,
        scale: scale,
      },
      this.setGridSize(sideLength, this.props.grid.width)
    );
  };

  widthIsLarger = (width, height) => {
    const gridWidth = Math.round((width * 0.8) / 100) * 100;
    const scale = this.props.grid.width / this.props.grid.height;
    const gridHeight = gridWidth / scale;
    this.setState(
      {
        gridWidth: gridWidth,
        gridHeight: gridHeight,
        scale: scale,
      },
      this.setGridSize(gridWidth, this.props.grid.width)
    );
  };

  heightIsLarger = (width, height) => {
    const gridHeight = Math.round((height * 0.8) / 100) * 100;
    const scale = this.props.grid.height / this.props.grid.width;
    const gridWidth = gridHeight / scale;
    this.setState(
      {
        gridWidth: gridWidth,
        gridHeight: gridHeight,
        scale: scale,
      },
      this.setGridSize(gridHeight, this.props.grid.height)
    );
  };

  setSize = () => {
    const dimensions = this.refs['gridContainer'].getBoundingClientRect();
    const screenWidth = dimensions.width;
    const screenHeight = dimensions.height;
    if (this.props.grid.width === this.props.grid.height) {
      this.gridIsSquare(screenWidth, screenHeight);
    } else if (this.props.grid.width > this.props.grid.height) {
      this.widthIsLarger(screenWidth, screenHeight);
    } else if (this.props.grid.width < this.props.grid.height) {
      this.heightIsLarger(screenWidth, screenHeight);
    }
  };

  onDragOver = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  onDrop = (ev, check) => {
    console.log('dropped');
    ev.preventDefault();
    const { draggedItem } = this.state;
    const gridCoords = ReactDOM.findDOMNode(this.refs['gridBox']).getBoundingClientRect();
    if (draggedItem.touch || check === 'touch') {
      console.log(ev.changedTouches);
      const touch = ev.changedTouches[0];
      draggedItem.xLoc = touch.clientX - gridCoords.x - draggedItem.width;
      draggedItem.yLoc = touch.clientY - gridCoords.y - draggedItem.length;
    } else {
      draggedItem.xLoc = ev.clientX - gridCoords.x - draggedItem.width;
      draggedItem.yLoc = ev.clientY - gridCoords.y - draggedItem.length;
    }
    console.log('draggedItem', draggedItem);
    if (draggedItem.length === 0) {
      return; // preventing 'invisible' elements being added
    } else {
      this.setState({
        counter: this.state.counter + 1,
        draggedItem: [],
        addedComponents: [...this.state.addedComponents, draggedItem],
      });
    }
  };

  itemIsDragging = (id, name, img, width, length, touch) => {
    console.log('itemIsDragging', id);
    const pieceId = id;
    const rotation = 0;
    const location = '';
    const xLoc = '';
    const yLoc = '';
    this.setState({
      draggedItem: {
        pieceId,
        name,
        img,
        length,
        width,
        rotation,
        location,
        xLoc,
        yLoc,
        touch,
      },
    });
  };

  saveGrid = (id, loc) => {
    console.log('saveGrid');
    var grid = this.state.addedComponents;
    if (loc) {
      grid.find((item) => item.pieceId === id).xLoc = loc.x;
      grid.find((item) => item.pieceId === id).yLoc = loc.y;
    }

    localStorage.setItem('gridElements', JSON.stringify(grid));
  };

  loadGrid = () => {
    var saved = JSON.parse(localStorage.getItem('gridElements'));
    console.log(saved);
    if (saved) {
      this.setState({
        addedComponents: saved,
      });
    }
  };

  deleteAll = () => {
    console.log('deleting');
    this.setState({
      addedComponents: [],
    });
  };

  saveItems = (id, length, width, img) => {
    console.log('saveItems', id);
    this.setState({
      addedComponents: [...this.state.components, { id, img, length, width }],
    });
  };

  onDragStart = (ev, id) => {
    console.log('dragging');
    this.setState({
      movingComponentId: id,
    });
  };

  deleteComponent = (id) => {
    const toBeDeleted = id;
    const filteredItems = this.state.addedComponents.filter(function (e) {
      return e.pieceId !== toBeDeleted;
    });
    this.setState({
      addedComponents: filteredItems,
    });
  };

  rotateComponent = () => {
    const toBeRotated = this.state.movingComponentId;
    const allComponents = this.state.addedComponents;
    const currentRotation = allComponents.find((e) => {
      return e.pieceId === toBeRotated;
    });
    const newRotation = currentRotation.rotation < 360 ? currentRotation.rotation + 90 : 0;
    Object.assign(
      allComponents.find((e) => e.pieceId === toBeRotated),
      {
        rotation: newRotation,
      }
    );
  };

  removeItem = (name) => {};

  saveActiveItem = (id) => {
    console.log('saveActiveItem');
    this.setState({
      activeItem: id,
    });
  };

  setGridSize = (gridSize, inputSize) => {
    const backgroundGridSize = gridSize / (inputSize / 10);
    this.setState({
      gridSize: `${backgroundGridSize}px ${backgroundGridSize}px`,
    });
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.history.push('/');
    this.props.logoutUser(this.props.history);
  };

  hideModal = (e) => {
    e.preventDefault();
    const modal = {
      active: false,
      type: '',
      title: '',
    };
    this.props.toggleModal(modal);
  };

  saveList = (items) => {
    this.setState({
      counterList: items,
    });
  };

  captureImage = () => {
    const element = document.getElementById('capture');
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      this.setState({
        imgData: imgData,
      });
    });
  };

  render() {
    const size = {
      width: this.state.gridWidth,
      height: this.state.gridHeight,
      backgroundSize: this.state.gridSize,
    };

    return (
      <div>
        <Navbar
          setSize={this.setSize}
          request={this.showRequestModal}
          help={this.showHelpModal}
          captureImage={this.captureImage}
          history={this.props.history}
        />
        <Modal
          show={this.props.grid.modal.active}
          handleClose={this.hideModal}
          title={this.props.grid.modal.title}
          type={this.props.grid.modal.type}
          components={this.state.counterList}
          captureImage={this.state.imgData}
          name={this.props.auth.user.name}
          email={this.props.auth.user.email}
          deleteAll={this.deleteAll}
          resize={this.props.grid.modal.resize}
        />
        <div className='builder-container'>
          <img alt='' className='container-background' src='images/background.jpg' />

          {/* eslint-disable-next-line */}
          <a onClick={this.onLogoutClick} className='logout-button ' href=''>
            Logout
          </a>
          <ObjectList
            saveLocation={this.saveLocation}
            itemIsDragging={this.itemIsDragging}
            saveItems={this.saveItems}
            itemIsDropped={this.onDrop}
          />

          <div
            id='capture'
            className='grid-container'
            ref='gridContainer'
            // onDrop={e => this.onDrop(e, "complete")}
          >
            <div className='width-distance'>{this.props.grid.width} ft</div>
            <div className='grid-box' ref='gridBox' style={size}>
              <div className='height-distance'>{this.props.grid.height} ft</div>
              <div
                ref='gridElement'
                className='grid'
                style={size}
                onDragOver={(e) => this.onDragOver(e)}
                onDrop={(e) => this.onDrop(e)}
              >
                {this.state.addedComponents.map((items) => {
                  return (
                    <GridObjectItem
                      key={items.pieceId}
                      pieceId={items.pieceId}
                      img={items.img}
                      width={items.width}
                      height={items.length}
                      location={items.location}
                      onDragStart={this.onDragStart}
                      gridWidth={this.state.gridWidth}
                      gridHeight={this.state.gridHeight}
                      saveGrid={this.saveGrid}
                      xLocation={items.xLoc}
                      yLocation={items.yLoc}
                      rotate={items.rotation}
                      scale={this.state.scale}
                      saveActiveItem={this.saveActiveItem}
                      currentlyActive={this.state.activeItem}
                      deleteComponent={this.deleteComponent}
                      gridX={this.state.gridX}
                      gridY={this.state.gridY}
                    />
                  );
                })}
              </div>
            </div>
            <div className='grid-note'>Each Square is 10ft x 10ft</div>
          </div>
          <button onClick={this.deleteAll} className='delete-all-button button hoverable' href=''>
            Delete All
          </button>
          <ComponentCounter addedComponents={this.state.addedComponents} saveList={this.saveList} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  grid: state.grid,
});

export default connect(mapStateToProps, { logoutUser, toggleModal })(GridLayout);
