import React from 'react';

export const NavButton = ({ name, onClick }) => (
  <button className='button hoverable' onClick={onClick}>
    {name}
  </button>
);

export const NavInput = ({ placeholder, onChange }) => (
  <input className='input' type='number' placeholder={placeholder} onChange={onChange} />
);

export const AuthText = () => (
  <div className='auth-text-container'>
    <h1 className='auth-text-header'>Create Your Dream Adventure Park with Ease!</h1>

    <p className='login-page-sub-header'>Custom Trampoline Parks Tailored for Your Family Entertainment Business</p>

    <p className='auth-text-paragraph'>
      We know you have a vision for your perfect park. You have the ideal location and exciting ideas for features, but
      translating that vision into a design can be challenging. That&apos;s where the{' '}
      <b>Shock Trampoline Park Builder</b> comes in.
    </p>

    <p className='auth-text-paragraph'>
      Our user-friendly, interactive tool empowers you to design your adventure park exactly how you envision it. With
      just a few clicks, you can customize the layout and features to match your unique concept. Once you&apos;re
      satisfied with your design, you can easily submit it to us for a streamlined quoting process.
    </p>

    <p className='auth-text-paragraph'>
      Don&apos;t let your dream park remain just a vision. <b>Register now and start building your future today!</b>
    </p>
  </div>
);

export const AuthTopBar = () => (
  <div className='auth-top-bar'>
    <img
      className='top-bar-img'
      src='images/shocklogov2.png'
      alt='shock-logo'
      onClick={() => window.open('https://shocktrampoline.com/', '_blank')}
    />
  </div>
);

export const UserTable = ({ name, email, phone, location, business, date }) => (
  <tr>
    <td>{name}</td>
    <td>{email}</td>
    <td>{phone}</td>
    <td>{location}</td>
    <td>{business}</td>
    <td>{date}</td>
  </tr>
);
