import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import classnames from 'classnames';
import { AuthText, AuthTopBar } from '../constants.js';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard'); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    const { errors } = this.state;
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div className='valign-wrapper fix-this-container'>
        <img alt='' className='container-background' src='images/background.jpg' />
        <AuthTopBar />
        <div className='auth-container'>
          <div className='auth-left'>
            <AuthText />
          </div>
          <div className='auth-right auth-details'>
            {isAuthenticated && (
              <div className='row'>
                <div className='col s12 has-background'>
                  <div className='col s12' style={{ paddingLeft: '11.250px' }}>
                    <h4>
                      <b style={{ color: '#dd137b' }}>Welcome back{user?.name ? ` ${user.name}!` : '!'}</b>
                    </h4>
                  </div>
                  <form noValidate onSubmit={this.onSubmit}>
                    <div className='col s12'>
                      <button
                        style={{
                          width: '150px',
                          borderRadius: '3px',
                          letterSpacing: '1.5px',
                          margin: '1rem 0 2rem 0',
                          backgroundColor: '#dd137b',
                        }}
                        type='submit'
                        className='btn btn-large waves-effect waves-light hoverable'
                      >
                        Dashboard
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {!isAuthenticated && (
              <div className='row'>
                <div className='col s8 offset-s2'>
                  <div className='col s12' style={{ paddingLeft: '11.250px' }}>
                    <h4>
                      <b style={{ color: '#dd137b' }}>Login below</b>
                    </h4>
                    <p className='text-darken-1' style={{ color: '#dd137b', fontWeight: 'bold' }}>
                      Don&apos;t have an account?{' '}
                      <Link to='/register' style={{ color: 'beige', fontWeight: 'bold' }}>
                        Register
                      </Link>
                    </p>
                  </div>

                  <form noValidate onSubmit={this.onSubmit}>
                    <div className='input-field col s12'>
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        error={errors.email}
                        id='email'
                        type='email'
                        className={classnames('', {
                          invalid: errors.email || errors.emailnotfound,
                        })}
                      />
                      <label htmlFor='email'>Email</label>
                      <span className='red-text'>
                        {errors.email}
                        {errors.emailnotfound}
                      </span>
                    </div>
                    <div className='input-field col s12'>
                      <input
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id='password'
                        type='password'
                        className={classnames('', {
                          invalid: errors.password || errors.passwordincorrect,
                        })}
                      />
                      <label htmlFor='password'>Password</label>
                      <span className='red-text'>
                        {errors.password}
                        {errors.passwordincorrect}
                      </span>
                    </div>

                    <div className='col s12' style={{ paddingLeft: '11.250px' }}>
                      <button
                        style={{
                          width: '150px',
                          borderRadius: '3px',
                          letterSpacing: '1.5px',
                          marginTop: '1rem',
                          backgroundColor: '#dd137b',
                        }}
                        type='submit'
                        className='btn btn-large waves-effect waves-light hoverable'
                      >
                        Login
                      </button>
                    </div>
                    <div className='col s12' style={{ paddingLeft: '11.250px' }}>
                      <p className='text-darken-1' style={{ color: '#dd137b' }}>
                        <Link to='/forgot' style={{ color: '#dd137b', fontWeight: 'bold' }}>
                          Forgot Password
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
